import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXSharpImg } from '../components/image';
import TimeAgo from "timeago-react"
import SchemaBlog from "../components/schema-blog"

export default function BuildTemplate({ data, location, pageContext }) {
  const { mdx, allMdx, allSitePage } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx

  const imgs = {}

  if (frontmatter.images) {
    frontmatter.images.forEach((image, i) => {
      const { childImageSharp } = image;
      const { fluid } = childImageSharp;
      imgs[`Img${i + 1}`] = ({ align, width }) =>
        <MDXSharpImg align={align} width={width} fluid={fluid || undefined} />        
    });
  }

  var bom;
  if (frontmatter.bom) {
    bom = (<section className="section">
      <h2 className="title is-2">Parts List</h2>
      <div className="content">
      <table className="table is-striped">
      <thead>
      <tr>
      <th>Qty</th>
      <th>Part #</th>
      <th>Description</th>
      <th>Manufacturer</th>
      </tr>
      </thead>
      <tbody>
      {frontmatter.bom.map((part, x) => 
        <tr key={x}>
        <td>{part.qty}</td>
        <td>{part.link ? <a href={part.link}>{part.part}</a>:part.part}</td>
        <td>{part.description}</td>
        <td>{part.mfr}</td>
        </tr>
      )}
      </tbody>
      </table>
      </div>
      </section>)
  }

  var contents = {};
  allMdx.edges.forEach(article => {
    contents[article.node.id] = {
      body: article.node.body
    }
  })

  const metaPosts = allSitePage.edges.map(edge => {
    return {
      id: edge.node.path,
    }
  })

  var pages = allSitePage.edges.map((article,i) => {
    var cn = "section"
    if (i % 2 === 1) {
      cn = "has-background-light section"
    }
    return (
      <section key={i} className={cn}>
      <div className="content">
        <h3 className="title is-3"><Link
          to={article.node.path}
          key={article.node.path}
        >
          {article.node.context.title}
        </Link></h3>
        <div className="columns">
        <div className="column is-2">
        {article.node.context.date && <small><TimeAgo datetime={article.node.context.date} locale="en" /></small>}
        </div>
        <div className="column">
        <MDXRenderer>{contents[article.node.context.id].body}</MDXRenderer>        
        </div>
        </div>
      </div>
      </section>
    )
  })


  return (
    <Layout posted={pageContext.date} updated={pageContext.updated} >
      <SEO
        title={pageContext.title}
        pathname={location.pathname}
        description={pageContext.excerpt} />
      <SchemaBlog
        pathname={location.pathname}
        title={pageContext.title}
        postList={metaPosts}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <h1 className="title is-1">{pageContext.title}</h1>
      <section className="section">
      <div className="columns">
      <div className="column is-one-third">
      <imgs.Img1 />
      </div>
      <div className="column">
      <h2 className="title is-2">Details</h2>      
      <div className="content">
        <MDXRenderer pages={allSitePage}>{body}</MDXRenderer>
        {frontmatter.github && <a className="button is-link" href={frontmatter.github}>GitHub repo</a>}
      </div>      
      </div>
      </div>
      </section>
      {bom}
      <section className="section">
      <h2 className="title is-2">Build Log</h2>
      </section>
      {pages}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String, $path: String) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
        description
        github
        bom {
          part
          mfr
          description
          qty
          link
        }
        images {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      excerpt(pruneLength: 300)
    }
    allMdx(filter: {frontmatter: {build: {eq: $path}}}) {
      edges {
        node {
          id
          body
          frontmatter {
            date
          }
        }
      }
    }
    allSitePage(
      sort: { fields: context___date, order: DESC }
      filter: { context: { parent: { eq: $path } } }
    ) {
      edges {
        node {
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
          }
          id
          path
        }
      }
    }
  }
`
